const MaterialManagementIcon = (props) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      color: "var(--one-color-interaction-disabled-neutral-3)"
    }}
  >
    <path
      d="M8.52393 4.5H4.5C3.39543 4.5 2.5 5.39543 2.5 6.5V17.5C2.5 18.6046 3.39543 19.5 4.5 19.5H19.5C20.6046 19.5 21.5 18.6046 21.5 17.5V8.375C21.5 7.27043 20.6046 6.375 19.5 6.375H12.0455C11.5099 6.375 10.9967 6.16017 10.6208 5.77862L9.94866 5.09638C9.57276 4.71483 9.05954 4.5 8.52393 4.5Z"
      stroke="#544F4F"
      stroke-width="2"
    />
    <path
      d="M9.72339 10.8298C9.72339 9.61386 10.7524 8.62769 12.0213 8.62769C13.2901 8.62769 14.3191 9.61386 14.3191 10.8298C14.3191 11.7873 13.2771 12.6212 12.7206 12.8586C12.351 13.0377 12.0213 13.4044 12.0213 13.8668V14.7553"
      stroke="#544F4F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse cx="12.0213" cy="17.0532" rx="0.255319" ry="0.25532" fill="#544F4F" stroke="#544F4F" stroke-width="1.2" />
  </svg>
);
export default MaterialManagementIcon;
