import React from "react";
import { withApollo, compose } from "react-apollo";
import { connect } from "react-redux";
import { displayNameSelector } from "../selectors/user";
import AppBarDesktop from "./AppBarDesktop";

const Appbar = ({ client, displayName, email, setSideMenu, activeMenuText, setActiveMenuText }) => {
  const id = "simple-popover";
  const logoLetter = displayName?.charAt(displayName.length - 1).toUpperCase() + displayName?.charAt(0).toUpperCase();

  return (
    <AppBarDesktop
      displayName={displayName}
      id={id}
      key={id}
      logoLetter={logoLetter}
      email={email}
      client={client}
      setSideMenu={setSideMenu}
      activeMenuText={activeMenuText}
      setActiveMenuText={setActiveMenuText}
    />
  );
};

const mapStateToProps = (store) => ({
  displayName: displayNameSelector(store),
  email: store.user.email
});

export default compose(connect(mapStateToProps), withApollo)(Appbar);
