import React, { useEffect, useState } from "react";
import "./App.scss";
import AppBar from "./views/AppBar";
import AppDrawer from "./views/AppDrawer";
import LandingPageUser from "./features/landingPageUser/LandingPageUser";
import TogglerContextWrapperSidebar from "./components/shared/TogglerContextWrapper";
import { useMobile } from "./utils/hooks";
import { StyledToastContainer } from "./features/snackbar/Snackbar";
import "react-toastify/dist/ReactToastify.css";
import UserNotification from "./features/userNotifications/UserNotification";
import ShortViewOfNotifyWrapper from "./features/landingPageNotifications/shortViewOfNotificaton/ShortViewOfNotifyContext/ShortViewOfNotifyWrapper";
import FiltersWrapper from "./features/landingPageNotifications/fullViewOfNotification/FiltersContext/FiltersWrapper";
import { SystemNotificationsWrapper } from "./features/userNotifications/systemNotification/SystemNotificationsWrapper";
import SiteWrapper from "./features/siteChange/SiteWrapper";
import SiteInitSelection from "./features/siteChange/SiteInitialSelector";
import SettingsContextWrapper from "./components/shared/SettingsContextWrapper";
import CheckAppRoles from "./components/shared/CheckAppRoles";
import { signOut } from "./utils/signout";
import { checkAuthUserAppRole } from "./utils/helpers/checkAuthUserAppRole";
import { AddOrEditContextWapper } from "./features/categories/contexts";
import styled from "styled-components";
import { getEnv } from "./utils/helpers/text";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { LIST_BACKEND_HEALTH_CHECKS } from "../src/gql/landingapi/queries";
import { API } from "aws-amplify";
// import ApplicationGroupsView from "./views/ApplicationGroupsView";
import { useSelector } from "react-redux";
import { CssBaseline } from "@mui/material";
import { Route, Switch } from "react-router";
import EquipmentsWrapper from "./features/equipments/equipments-context/EquipmentsWrapper";
import AllWidgetsWrapper from "./features/adminPanel/widgets/context/AllWidgetsWrapper";
import DashboardPage from "./features/dashboard/DashboardPage";
import ApplicationMainPage from "./features/applications/ApplicationMainPage";
import { HOME_SUB_MENU, updateEmailURL } from "./constants";
import AdminPanel from "./features/adminPanel/AdminPanel";
import IndexPage from "./features/IndexPage";
import LandingPageNotifications from "./features/landingPageNotifications/LandingPageNotifications";
import EquipmentMainContainer from "./features/equipments/EquipmentMainContainer";
import { NotificationLoader } from "./features/landingPageNotifications/NotificationLoader";
import { AwsRum } from "aws-rum-web";
const currentEnv = getEnv();

/**
 * Code for AWS cloud watch RUM
 */
let awsRum = null; //very important otherwise variable is local to try/catch block and will be garbage collected, and possible even removed during compilation (production optimized build)
try {
  let app_telemetries = currentEnv === "dev" || currentEnv === "test" ? ["performance", "errors", "http"] : ["errors"];
  // help for config: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
  awsRum = new AwsRum(
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID,
    process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION,
    process.env.REACT_APP_AWS_RUM_APPLICATION_REGION,
    {
      sessionSampleRate: 1, //record 100% of sessions
      sessionEventLimit: 0, //no limit on number of events per session
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: app_telemetries,
      // in development, record all telemetries
      // telemetries: ["errors"], // for production/staging, only errors
      allowCookies: true,
      enableXRay: false
    }
  );
  // console.log(awsRum);
  //https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-custom-events.html
  //Caution if you send empty data, you get a strange error:  1 validation error detected: Value null at 'rumEvents.3.member.details' failed to satisfy constraint: Member must not be null
  // Note: custom events must be enabled in the cloudwatch console, otherwise they are simply ignore.
  awsRum.recordEvent("my_custom_event", { value_1: "App Loaded" }); //record custom event (remove later)
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
// Code for AWS cloud watch RUM ENDS

const UniqueEnv = styled.div`
  .MuiAppBar-positionAbsolute {
    top: 41px;
  }
`;
// const TopBanner = styled.div`
//   .MuiAppBar-positionAbsolute {
//     top: 49px;
//   }
// `;
const TopBannerAndUniqueEnv = styled.div`
  .MuiAppBar-positionAbsolute {
    top: 91px;
  }
`;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("recordingError: " + error);
    awsRum.record(error);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Clear Error
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  const [enableVal, setEnableVal] = useState(false);
  const [sideMenu, setSideMenu] = useState(HOME_SUB_MENU);
  const [activeMenuText, setActiveMenuText] = useState(null);
  const getBackendAuthChecks = async () => {
    try {
      const backendBuildInfo = await API.graphql({
        query: LIST_BACKEND_HEALTH_CHECKS
      });
      const items = backendBuildInfo?.data?.listBackendHealthChecks?.items;
      const trackDisable = items.map((item) => item?.enableAnalyticsTracking);
      const enableTracking = trackDisable[0] ?? false;
      setEnableVal(enableTracking);
    } catch (error) {
      console.log("Matomo api error..", error);
    }
  };
  useEffect(() => {
    getBackendAuthChecks();
  }, []);
  const TopBannerDatas = useSelector((state) => state.user.NotificationBanner);
  // Matomo code added for analytics
  // urlBase: optional, default value: `${urlBase}matomo.php`
  // siteId : optional, default value: `${urlBase}matomo.js`
  // disabled :optional, false by default. Makes all tracking calls no-ops if set to true.
  // linkTracking :optional, default value: true
  // active: optional, default value: true
  // seconds: optional, default value: `15

  const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_BASE_URL,
    siteId: process.env.REACT_APP_SITE_ID,
    trackerUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.php",
    srcUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.js",
    disabled: enableVal,
    linkTracking: true,
    trackPageView: true,
    heartBeat: {
      active: true,
      seconds: 10
    }
  });
  const isMobile = useMobile();
  return (
    <ErrorBoundary>
      <MatomoProvider value={instance}>
        <CheckAppRoles checkRolesMethod={checkAuthUserAppRole} logout={signOut}>
          <SiteWrapper>
            <SiteInitSelection>
              <LandingPageUser>
                <FiltersWrapper>
                  <TogglerContextWrapperSidebar initialState={!isMobile}>
                    <ShortViewOfNotifyWrapper>
                      <SystemNotificationsWrapper>
                        <CssBaseline />
                        {currentEnv && TopBannerDatas?.enabled ? (
                          <TopBannerAndUniqueEnv>
                            <AppBar
                              setSideMenu={setSideMenu}
                              activeMenuText={activeMenuText}
                              setActiveMenuText={setActiveMenuText}
                            />
                          </TopBannerAndUniqueEnv>
                        ) : currentEnv ? (
                          <UniqueEnv>
                            <AppBar
                              setSideMenu={setSideMenu}
                              activeMenuText={activeMenuText}
                              setActiveMenuText={setActiveMenuText}
                            />
                          </UniqueEnv>
                        ) : (
                          <AppBar
                            setSideMenu={setSideMenu}
                            activeMenuText={activeMenuText}
                            setActiveMenuText={setActiveMenuText}
                          />
                        )}
                        <AppDrawer sideMenu={sideMenu} activeMenuText={activeMenuText}>
                          <SettingsContextWrapper>
                            <AddOrEditContextWapper>
                              <Switch>
                                <Route exact path="/">
                                  <IndexPage />
                                </Route>
                                <Route exact path="/notifications">
                                  <LandingPageNotifications id={"all"} />
                                </Route>
                                <Route exact path="/notificationsApp">
                                  <AllWidgetsWrapper>
                                    <NotificationLoader />
                                  </AllWidgetsWrapper>
                                </Route>
                                <Route exact path="/home/dashboard">
                                  <AllWidgetsWrapper>
                                    <DashboardPage />
                                  </AllWidgetsWrapper>
                                </Route>
                                <Route exact path="/home/application">
                                  <ApplicationMainPage name="application" />
                                </Route>
                                <Route exact path="/home/quick-access">
                                  <ApplicationMainPage name="quick-access" />
                                </Route>
                                <Route exact path="/home/my-lab">
                                  <EquipmentsWrapper>
                                    <EquipmentMainContainer />
                                  </EquipmentsWrapper>
                                </Route>
                                <Route
                                  exact
                                  path={[
                                    "/admin/general/application-categories",
                                    "/admin/general/widgets",
                                    "/admin/general/banner-msg",
                                    "/admin/general/send-notification",
                                    `${updateEmailURL}`
                                  ]}
                                >
                                  <AdminPanel />
                                </Route>
                              </Switch>
                            </AddOrEditContextWapper>
                          </SettingsContextWrapper>
                        </AppDrawer>
                        <StyledToastContainer />
                      </SystemNotificationsWrapper>
                    </ShortViewOfNotifyWrapper>
                  </TogglerContextWrapperSidebar>
                  <UserNotification />
                </FiltersWrapper>
              </LandingPageUser>
            </SiteInitSelection>
          </SiteWrapper>
        </CheckAppRoles>
      </MatomoProvider>
    </ErrorBoundary>
  );
}

export default App;
